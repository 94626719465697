import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { gql, useQuery } from '@apollo/client';
import { Image } from 'cloudinary-react';
import { motion } from 'framer-motion';
import cx from 'classnames';
import { NextSeo } from 'next-seo';

import config from 'config';
import { useAuth } from 'context/Auth';
import { ProgramPageProvider } from 'context/ProgramPage';
import useIsClient from 'hooks/useIsClient';
import useScreen from 'hooks/useScreen';
import useCustomTheme from 'hooks/useCustomTheme';
import NotFoundPage from 'pages/404';
import AuthMenu from 'components/global/AuthMenu';
import HorizontalScrollContainer from 'components/common/HorizontalScrollContainer';
import HSubnav from 'components/common/HSubnav';
import HSubnavItem from 'components/common/HSubnavItem';
import Link from 'components/common/Link';
import Navbar from 'components/common/Navbar';
import NavbarIconButton from 'components/common/NavbarIconButton';
import NavbarRoundedButton from 'components/common/NavbarRoundedButton';
import ProgramPageFooter from 'components/program-page/ProgramPageFooter';
import { faChartMixed } from '@fortawesome/pro-regular-svg-icons';

const GET_PROGRAM = gql`
  query GetProgramLayoutFromProgram($id: String!) {
    findPrograms(id: $id) {
      id
      name
      accentColor
      campaignLogo
      primaryColor
      myPermissions

      ownerOrganization {
        id
        logo
        name
      }

      pages {
        id
        slug
        name
      }
    }
  }
`;

const ProgramPageLayout = ({ id, className, children }) => {
  const router = useRouter();
  const {
    query: { page },
  } = router;
  const { isLoggedIn } = useAuth();
  const isClient = useIsClient();

  const screen = useScreen();
  const NavbarButton = screen.lg ? NavbarRoundedButton : NavbarIconButton;

  const { data, loading } = useQuery(GET_PROGRAM, { variables: { id } });
  const program = useMemo(() => data?.findPrograms[0], [data]);

  const isProgramManager = useMemo(() => {
    if (!isLoggedIn || !program) return false;
    return program.myPermissions.includes('manageCampaign');
  }, [isLoggedIn, program]);

  const theme = useCustomTheme({
    primary: program?.primaryColor,
    secondary: program?.accentColor,
  });

  const logo = program?.campaignLogo ?? program?.ownerOrganization.logo;

  if (!loading && !program) return <NotFoundPage />;
  if (!program) return null;

  return (
    <ProgramPageProvider programId={program.id} theme={theme}>
      <NextSeo
        title={`Support ${program.name}`}
        openGraph={{
          // Duplicated here so it doesn't get the site name appended
          title: `Support ${program.name}`,
          url: `${config('/hostname')}${router.asPath}`,
        }}
      />
      <motion.div
        initial={false}
        animate={{ opacity: isClient ? 1 : 0 }}
        className="bg-gray-100 min-h-screen flex flex-col"
      >
        <Navbar
          className="sticky top-0 z-40 h-20 bg-white border-b border-gray-400 shrink-0"
          left={
            <Link href={`/pr/${program.id}`}>
              <Image
                publicId={logo}
                alt=""
                width={260}
                height={120}
                crop="limit"
                quality="auto"
                fetchFormat="auto"
                className="block w-40 md:w-auto h-10 object-contain object-left"
              />
            </Link>
          }
          rightClassName="overflow-hidden"
          right={
            <div className="overflow-hidden flex items-center gap-x-8">
              {screen.md && (
                <div className="flex-1 overflow-hidden">
                  <HorizontalScrollContainer>
                    <div className="flex items-center gap-x-4">
                      {program.pages?.map((pg) => {
                        if (pg.slug === '/') return null;
                        return (
                          <Link
                            key={pg.slug}
                            href={`/pr/${program.id}/pages/${pg.slug}`}
                            className="py-2 font-medium text-gray-800 shrink-0"
                          >
                            {pg.name}
                          </Link>
                        );
                      })}
                    </div>
                  </HorizontalScrollContainer>
                </div>
              )}
              <div className="shrink-0 flex items-center gap-x-4">
                {isProgramManager && (
                  <NavbarButton
                    icon={faChartMixed}
                    label="Dashboard"
                    href={`/manage/pr/${program.id}`}
                  />
                )}
                <AuthMenu />
              </div>
            </div>
          }
        />
        {!screen.md && program.pages?.length > 1 && (
          <div className="shadow-inner bg-white">
            <HSubnav className="px-6 pt-5 pb-2">
              {program.pages?.map((item) => (
                <HSubnavItem
                  key={item.slug}
                  href={
                    item.slug === '/' ? `/pr/${program.id}` : `/pr/${program.id}/pages/${item.slug}`
                  }
                  className="text-sm"
                  isActive={(!page && item.slug === '/') || page === (item.slug ?? item.id)}
                  activeColor="gray-800"
                >
                  {item.slug === '/' ? 'Home' : item.name}
                </HSubnavItem>
              ))}
            </HSubnav>
          </div>
        )}
        <main
          className={cx('bg-white shrink-0 grow', className)}
          style={{ minHeight: 'calc(100vh - 5rem)' }}
        >
          {children}
        </main>

        <ProgramPageFooter />
      </motion.div>
    </ProgramPageProvider>
  );
};

ProgramPageLayout.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

ProgramPageLayout.defaultProps = {
  className: '',
};

export default ProgramPageLayout;
