import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltToTop } from '@fortawesome/pro-solid-svg-icons';
import { animateScroll } from 'react-scroll';

import { useProgramPage } from 'context/ProgramPage';
import { formatAddress } from 'lib/formatters';
import Link from 'components/common/Link';
import LogoKey from '../../svg/logo-key.svg';

const GET_PROGRAM = gql`
  query GetProgram($id: String!) {
    findPrograms(id: $id) {
      id
      contactPhone
      contactEmail

      ownerOrganization {
        id
        address1
        address2
        city
        stateProv
        postalCode
        name
        website
      }

      pages {
        id
        name
        slug
      }
    }
  }
`;

const ProgramPageFooter = () => {
  const { programId } = useProgramPage();
  const { data } = useQuery(GET_PROGRAM, { variables: { id: programId } });
  const program = useMemo(() => data?.findPrograms[0], [data]);

  const hasContact = Boolean(program?.contactPhone || program?.contactEmail);
  const address = formatAddress({
    address1: program?.ownerOrganization.address1,
    address2: program?.ownerOrganization.address2,
    city: program?.ownerOrganization.city,
    stateProv: program?.ownerOrganization.stateProv,
    postalCode: program?.ownerOrganization.postalCode,
  });

  if (!program) return null;

  return (
    <footer>
      <section className="shrink-0 bg-gray-200 text-gray-700 py-12 lg:py-16">
        <div className="container">
          <div className="flex justify-between gap-x-16">
            <div className="grow shrink overflow-hidden">
              <div className="flex flex-col md:flex-row gap-x-16 gap-y-8">
                <div>
                  <h3 className="font-medium">Program Links</h3>
                  <p className="mt-3">
                    <Link
                      href={`/pr/${program.id}/`}
                      className="text-gray-700 hover:text-gray-900 transition-colors duration-200 break-all"
                    >
                      Home
                    </Link>
                  </p>
                  {program.pages?.map((page) => {
                    if (page.slug === '/') return null;
                    return (
                      <p className="mt-2" key={page.slug}>
                        <Link
                          href={`/pr/${program.id}/pages/${page.slug}`}
                          className="text-gray-700 hover:text-gray-900 transition-colors duration-200 break-all"
                        >
                          {page.name}
                        </Link>
                      </p>
                    );
                  })}
                </div>
                <div>
                  <h3 className="font-medium">{program.ownerOrganization.name}</h3>
                  {address && <p className="mt-2 whitespace-pre-line">{address}</p>}
                  {program.ownerOrganization.website && (
                    <p className="mt-3">
                      <Link
                        href={program.ownerOrganization.website}
                        className="text-theme-primary hover:text-theme-primary-light transition-colors duration-200 break-all"
                      >
                        {program.ownerOrganization.website}
                      </Link>
                    </p>
                  )}
                </div>
                {hasContact && (
                  <div>
                    <h3 className="font-medium">Help &amp; Support</h3>
                    {program.contactEmail && (
                      <p className="mt-3">
                        <Link
                          href={`mailto:${program.contactEmail}`}
                          className="text-theme-primary hover:text-theme-primary-light transition-colors duration-200 break-all"
                        >
                          {program.contactEmail}
                        </Link>
                      </p>
                    )}
                    {program.contactPhone && <p className="mt-2">{program.contactPhone}</p>}
                  </div>
                )}
              </div>
            </div>
            <div className="shrink-0">
              <button
                type="button"
                className="flex flex-col justify-center items-center group text-gray-700 transition-colors duration-200"
                onClick={() => animateScroll.scrollToTop()}
              >
                <span className="flex justify-center items-center w-12 h-12 rounded-full bg-gray-500 group-hover:bg-gray-600 transition-colors duration-200">
                  <FontAwesomeIcon icon={faArrowAltToTop} size="lg" className="text-gray-200" />
                </span>
                <span className="font-medium hover:text-gray-800 pt-3">Back to top</span>
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-800 py-10 md:py-6 text-gray-300">
        <div className="container">
          <div className="flex flex-col md:flex-row-reverse -mt-4 justify-between items-center text-center md:text-left">
            <Link
              external
              href="https://pledgeit.org"
              className="lowercase text-lg pt-4 flex items-center"
            >
              Powered by <LogoKey className="fill-white inline-block h-[1.625rem] ml-3" />
            </Link>
            <p className="text-sm pt-4">
              Copyright &copy;{new Date().getFullYear()} • All rights reserved •{' '}
              <Link
                href="/privacy"
                className="text-gray-300 hover:text-gray-500 transition-colors duration-200"
              >
                Privacy Policy
              </Link>{' '}
              •{' '}
              <Link
                href="/tos"
                className="text-gray-300 hover:text-gray-500 transition-colors duration-200"
              >
                Terms of Use
              </Link>
            </p>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default ProgramPageFooter;
