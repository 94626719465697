import PropTypes from 'prop-types';

import CampaignPageSectionHeader from '../campaign-page/CampaignPageSectionHeader';
import ProgramPageCampaigns from './ProgramPageCampaigns';

const ProgramPageCampaignListing = ({ title }) => (
  <div className="my-12 lg:my-24">
    <div className="sm:container">
      <CampaignPageSectionHeader
        title={title || 'Get Involved'}
        titleClassName="text-gray-800"
        className="mb-8"
      />
      <div className="lg:w-2/3 lg:mx-auto">
        <ProgramPageCampaigns className="mt-8 -mx-3 sm:mx-0" />
      </div>
    </div>
  </div>
);

ProgramPageCampaignListing.propTypes = {
  title: PropTypes.string,
};

ProgramPageCampaignListing.defaultProps = {
  title: '',
};

export default ProgramPageCampaignListing;
