import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/pro-solid-svg-icons';

import Link from 'components/common/Link';
import Card from 'components/common/Card';
import { formatDateRange } from 'lib/formatters';

const ProgramPageCampaign = ({
  id,
  name,
  isPerformanceEnabled,
  performanceStartDate,
  performanceEndDate,
  event,
}) => {
  const dateRange = useMemo(() => {
    if (event) return formatDateRange(event.startDate, event.endDate);
    if (!isPerformanceEnabled) return null;
    return formatDateRange(performanceStartDate, performanceEndDate);
  }, [isPerformanceEnabled, event, performanceStartDate, performanceEndDate]);

  return (
    <Card
      as={Link}
      href={`/c/${id}/`}
      depth="none"
      padding="min"
      border
      className="group hover:border-teal-500 hover:shadow-lg"
    >
      <div className="flex items-center justify-between p-4">
        <div className="grow">
          <p className="font-medium">{name}</p>
          {dateRange && <p className="text-sm text-gray-600">{dateRange}</p>}
        </div>
        <div className=" shrink-0 pl-4">
          <button
            type="button"
            className="text-theme-secondary group-hover:text-theme-secondary-light"
          >
            <FontAwesomeIcon icon={faArrowCircleRight} size="lg" className="text-2xl" />
          </button>
        </div>
      </div>
    </Card>
  );
};

ProgramPageCampaign.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isPerformanceEnabled: PropTypes.bool.isRequired,
  performanceStartDate: PropTypes.string,
  performanceEndDate: PropTypes.string,
  event: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
};

ProgramPageCampaign.defaultProps = {
  performanceStartDate: null,
  performanceEndDate: null,
  event: null,
};

export default ProgramPageCampaign;
