import { ElementType } from 'react';

import cx from 'lib/cx';
import HorizontalScrollContainer from './HorizontalScrollContainer';

type HSubnavProps<C extends ElementType> = PolymorphicComponentProp<
  C,
  {
    className?: string;
    children: NonEmptyReactNode;
  }
>;

const HSubnav = <C extends ElementType = typeof HorizontalScrollContainer>({
  as,
  children,
  className = '',
  ...props
}: HSubnavProps<C>) => {
  const Component = as || HorizontalScrollContainer;
  return (
    <Component className={cx('flex items-center gap-x-6', className)} {...props}>
      {children}
    </Component>
  );
};

export default HSubnav;
