import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';

import Tiles from 'components/common/Tiles';
import ProgramPageCampaign from 'components/program-page/ProgramPageCampaign';

import { useProgramPage } from 'context/ProgramPage';

const GET_CAMPAIGNS = gql`
  query GetCampaigns($programWhere: SequelizeJSON!) {
    findCampaigns(order: "name", where: $programWhere) {
      id
      name
      isPerformanceEnabled
      performanceStartDate
      performanceEndDate

      event {
        startDate
        endDate
      }
    }
  }
`;

const ProgramPageCampaigns = () => {
  const { programId } = useProgramPage();
  const { data, loading } = useQuery(GET_CAMPAIGNS, {
    variables: { programWhere: { parentId: programId } },
  });
  const campaigns = useMemo(() => data?.findCampaigns, [data]);

  if (!campaigns && loading) return null;
  if (campaigns.length === 0) {
    return (
      <div>
        <p className="text-gray-600 text-lg text-center">There are not yet any campaigns to join</p>
      </div>
    );
  }

  return (
    <Tiles columns={1} spacing="xs">
      {campaigns.map((campaign) => (
        <ProgramPageCampaign key={campaign.id} {...campaign} />
      ))}
    </Tiles>
  );
};

export default ProgramPageCampaigns;
