import { ElementType } from 'react';
import cx from 'classnames';

import Link from './Link';

type HSubnavItemProps<C extends ElementType> = PolymorphicComponentProp<
  C,
  {
    className?: string;
    isActive?: boolean;
    activeColor?: 'green' | 'gray-800';
    theme?: 'light' | 'dark';
    children: NonEmptyReactNode;
  }
>;

const HSubnavItem = <C extends ElementType = typeof Link>({
  as,
  children,
  className = '',
  isActive = false,
  activeColor = 'green',
  theme = 'light',
  ...props
}: HSubnavItemProps<C>) => {
  const Component = as || Link;
  return (
    <Component
      className={cx(
        'pb-2 font-medium shrink-0 border-b-2 transition-colors duration-200',
        {
          'border-transparent': !isActive,
          'text-gray-600': !isActive && theme === 'light',
          'text-gray-800': isActive && theme === 'light',
          'text-gray-500': !isActive && theme === 'dark',
          'text-white': isActive && theme === 'dark',
          'border-green-500': isActive && activeColor === 'green',
          'border-gray-800': isActive && activeColor === 'gray-800',
        },
        className
      )}
      {...props}
    >
      {children}
    </Component>
  );
};

export default HSubnavItem;
